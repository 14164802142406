import { Link } from 'react-router-dom';
import { Pretendard } from '../Typography';
import { FooterBox, FooterContainer, FooterLogo, FooterTextDivider, MobileFooter } from './styles';
import { HorizontalDivider } from '../Divider';
import useWindowSize from '../../hooks/useWindowSize';

const Footer = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <MobileFooter>
        <img
          style={{
            width: '23%',
            marginTop: '40px',
            marginLeft: '4vw',
          }}
          src="/responsive/footer/footer-logo.png"
          alt=""
        />
        <img
          style={{
            width: '100%',
          }}
          src="/responsive/footer/footer.svg"
          alt=""
        />
      </MobileFooter>
    );
  }
  return (
    <FooterContainer>
      <FooterBox>
        <div>
          <FooterLogo />
          <address>
            <Pretendard
              as="p"
              size="medium"
              style={{
                color: '#A9A9A9',
              }}
            >
              <span>주소 : 경기도 화성시 수노을중앙로 136, 메가타워 (스타벅스건물) 3층</span>
              <FooterTextDivider>|</FooterTextDivider>
              <span>대표자 : 김승주</span>
            </Pretendard>
            <Pretendard
              as="p"
              size="medium"
              style={{
                color: '#A9A9A9',
              }}
            >
              <span>
                <Link to="mailto:hyebr@naver.com">이메일 : plkmj1@naver.com</Link>
              </span>
              <FooterTextDivider>|</FooterTextDivider>
              <span>사업자등록번호 : 859-27-01937</span>
              <FooterTextDivider>|</FooterTextDivider>
              <span>
                <a href="tel:031-357-1897">TEL : 031 - 357 - 1897</a>
              </span>
            </Pretendard>
          </address>
        </div>
      </FooterBox>
      <HorizontalDivider
        style={{
          height: '1px',
          backgroundColor: '#5C5C5C',
        }}
      />
      <FooterBox>
        <Pretendard
          as="p"
          size="medium"
          style={{
            color: '#A9A9A9',
          }}
        >
          ⓒ 2021 에스본한의원. All rights reserved.
        </Pretendard>
      </FooterBox>
    </FooterContainer>
  );
};

export default Footer;
